import { createContext } from 'react';

import { DEFAULT_THEME } from '../../consts';

export type Theme = 'dark' | 'light';

export default createContext<{ theme: Theme; setTheme(theme: Theme): void }>({
  theme: DEFAULT_THEME,
  setTheme() {
    return void 0;
  },
});
