import * as React from 'react';

import styles from './styles.module.css';

const ProgressBar: React.FC = () => {
  const barRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = React.useCallback(() => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;

    if (barRef.current !== null) {
      barRef.current.style.width = scrolled + '%';
    }
  }, [barRef]);

  return (
    <div className={styles.Container}>
      <div className={styles.Bar} ref={barRef} />
    </div>
  );
};

export default ProgressBar;
