import '@fortawesome/fontawesome-svg-core/styles.css';
import '../../styles/app.css';

import { config } from '@fortawesome/fontawesome-svg-core';
import * as cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { DEFAULT_THEME } from '../../../consts';
import ThemeProvider, { Theme } from '../../providers/ThemeProvider';
import Footer from '../Footer';
import Header from '../Header';
import styles from './styles.module.css';

config.autoAddCss = false;

function getInitialTheme(): Theme {
  if (typeof localStorage === 'undefined') {
    // SSR
    return DEFAULT_THEME;
  }

  return (localStorage.getItem('theme') as Theme) ?? DEFAULT_THEME;
}

type LayoutProps = {
  showTitle: boolean;
  isPostTemplate: boolean;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  showTitle,
  isPostTemplate,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [theme, setTheme] = React.useState(getInitialTheme());

  return (
    <ThemeProvider.Provider value={{ theme, setTheme }}>
      <div className={cn(styles.Site, theme)}>
        <Header
          siteTitle={data.site.siteMetadata.title}
          showTitle={showTitle}
          isPostTemplate={isPostTemplate}
        />
        {children}
        <Footer />
      </div>
    </ThemeProvider.Provider>
  );
};

export default Layout;
