import { Link } from 'gatsby';
import * as React from 'react';

import { SOCIAL_MEDIA_LINKS } from '../../../consts';
import ToggleMode from '../ToggleMode';
import Icon from './Icon';
import ProgressBar from './ProgressBar';
import styles from './styles.module.css';

type HeaderProps = {
  siteTitle?: string;
  showTitle: boolean;
  isPostTemplate: boolean;
};

const Header: React.FC<HeaderProps> = ({
  siteTitle = '',
  showTitle,
  isPostTemplate,
}) => {
  return (
    <header>
      {isPostTemplate && <ProgressBar />}

      <div className={styles.Inner}>
        {showTitle && <Link to="/">{`${siteTitle}`}</Link>}

        <ul className={styles.MediaIcons}>
          {SOCIAL_MEDIA_LINKS.map(link => (
            <Icon key={link.name} {...link} />
          ))}
        </ul>

        <ToggleMode />
      </div>
    </header>
  );
};

export default Header;
