import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import styles from './styles.module.css';

type IconProps = {
  value: string;
  name: string;
  icon: IconProp;
};

const Icon: React.FC<IconProps> = ({ name, value, icon }) => {
  return (
    <li>
      <a
        className={styles.Icon}
        href={value}
        title={name}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={icon} size="lg" />
      </a>
    </li>
  );
};

export default Icon;
