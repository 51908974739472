import { faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const DEFAULT_THEME: 'dark' = 'dark'; // or light, or perhaps window.matchMedia('(prefers-color-scheme: dark)').matches?

export const PROFILE_IMAGE_NAME = `profile.jpg`; // Place profile.jpg/.jpeg/.png in _assets folder

export const SOCIAL_MEDIA_LINKS = [
  { value: 'mailto:jakub@rozek.tech', name: 'Email', icon: faEnvelope },
  { value: 'https://github.com/P0lip', name: 'GitHub', icon: faGithub },
  { value: 'https://medium.com/@P0lip', name: 'Medium', icon: faMedium },
];
