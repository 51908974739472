import './styles.css';

import * as React from 'react';

import config from '../../../customize';

const Footer: React.FC = () => {
  return (
    <footer>
      © {new Date().getFullYear()}
      {` `}
      {config.author}
      <a
        href="https://github.com/EllisMin/gatsby-blog-mdx"
        target="_blank"
        rel="noopener noreferrer"
      >
        UI based on gatsby-blog-mdx
      </a>
    </footer>
  );
};

export default Footer;
