/* Customization Guide available on:
  https://gatsby-blog-mdx.now.sh/2020/05/4-customize/
  ======================================== */
const config = {
  /* Site
  ========================================= */
  title: `Jakub Rożek - engineering & travelling`, // Displayed in header
  description: `Jakub goes down the rabbithole.`, // Site description
  maxWidth: '768px', // Max width of website
  siteUrl: `https://rozek.tech/`, // For sitemap & RSS feed
  useScrollIndicator: true, // Use scroll indicator on top of screen in posts

  /* Profile
  ========================================= */
  author: `Jakub Rożek`, // Name shows on profile
  profileDescription:
    'I occasionally happen to write code that works in the way others expect it to work', // Shows under author name
  location: 'Kyiv, Ukraine', // Location under profileDescription. "" --> disabled

  /* More Custom Settings
  ========================================= */
  showTimeToRead: true, // Shows time to read for each post in main page
  breakCodeLines: false, // Breaks long code lines instead of having horizontal scroll
  faviconSrc: `_assets/icon-code.png`, // Favicon
  gaTrackingId: ``, // Your google analytics tracking id--i.e. UA-*****
  googleAdSenseId: ``, // Your google AdSense client id--i.e. ca-pub-****
};

module.exports = config;
