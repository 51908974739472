import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Switch from 'react-switch';

import useTheme from '../../hooks/useTheme';
import ThemeProvider from '../../providers/ThemeProvider';
import styles from './styles.module.css';

const CustomSwitch: React.FC = () => {
  const theme = useTheme();
  const { setTheme } = React.useContext(ThemeProvider);
  const checked = theme === 'dark';

  const handleClick = (): void => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  return (
    <Switch
      className={styles.Switch}
      onChange={handleClick}
      checked={checked}
      offColor="#bbb"
      onColor="#4a4a4a"
      uncheckedIcon={
        <FontAwesomeIcon className={styles.IconMoon} icon={faMoon} size="sm" />
      }
      checkedIcon={
        <FontAwesomeIcon className={styles.IconSun} icon={faSun} size="sm" />
      }
      handleDiameter={21}
      height={23}
      width={40}
      onHandleColor="#333"
    />
  );
};

export default CustomSwitch;
